.reviewSection {
  width: 100%;
}

.sectionHeader {
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: var(--color-slate-700);
  margin: 0 0 0.5rem 0;
}
.reviewList {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, auto));
  gap: 1rem;
  margin: 0;
  padding: 0;
  // max-height: 200vh;
  width: 100%;
  list-style: none;

  @media (min-width: 48em) {
    grid-template-columns: repeat(2, minmax(0, auto));
  }

  @media (min-width: 80em) {
    grid-template-columns: repeat(3, minmax(0, auto));
  }
}

.nav {
  width: 100%;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 40em) {
    flex-direction: row;
    margin-top: 2rem;
  }

  button {
    width: 50%;
    padding: 1rem;
    border-radius: 6px;
    cursor: pointer;
    text-decoration: none;

    @media (min-width: 40em) {
      width: max-content;
    }
  }
  .first {
    margin-right: auto;
  }
  .last {
    margin-left: auto;
  }
}

.cardDisclaimer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  width: 100%;
  border-radius: var(--border-radius);
  background-color: rgba(186, 230, 253, 0.3);
  border: 2px dashed rgba(125, 211, 252, 0.5);
  color: var(--color-blue-900);
  cursor: pointer;
  svg {
    width: 5rem;
    height: 5rem;
  }

  span {
    font-size: var(--font-size-xl);
    line-height: var(--line-height-xl);
  }

  a {
    text-decoration: none;
    background-color: var(--color-blue-200);
    color: var(--color-blue-800);
    padding: 0.5rem 1rem;
    border-radius: 6px;

    &:hover {
      color: var(--color-white);
      background-color: var(--color-blue-900);
    }
  }
}
