.section {
  margin-top: 6rem;

  &:last-child {
    margin-top: 8rem;
  }
}
.sectionHeader {
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: var(--color-slate-700);
  margin: 0 0 1rem 0;
}

.companyList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
  gap: 2rem;
  place-items: center;

  @media (min-width: 40em) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .companyItem {
    max-width: 5rem;
    img {
      width: 100%;
      height: auto;
    }
  }
}
