.pageHeader {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  // grid-template-columns: repeat(3, minmax(0, 1fr));
  // max-width: 60rem;

  .title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;

    @media (min-width: 45em) {
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
    }
    .imgArea {
      flex-grow: 1;
      display: grid;
      place-content: center;
    }
    img {
      width: 4rem;
      height: auto;
      border-radius: calc(var(--border-radius) / 2);
      overflow: hidden;
      object-fit: contain;
    }
  }

  .pageTitle {
    font-size: var(--font-size-3xl);
    line-height: var(--line-height-3xl);
    color: var(--color-slate-800);
    font-weight: 600;
    padding: 0;
    margin: 0;
  }
}

.pageBody {
  // max-width: 60rem;
}

.section {
  @media (min-width: 64em) {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1rem;
  }

  &:first-of-type {
    margin-bottom: 3rem;
  }
  & > div:last-of-type {
    margin-bottom: 1rem;
  }
}

.row_2 {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;

  & > * {
    grid-column: 1 / -1;

    & ul {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      @media (min-width: 45em) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }
  }

  @media (min-width: 64em) {
    grid-column: 2 / span 2;
  }
}
.row_3 {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1rem;

  @media (min-width: 40em) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media (min-width: 64em) {
    grid-column: 2 / span 2;
  }
}
.rowFull {
  grid-template-columns: 1fr;
  @media (min-width: 64em) {
    grid-column: 2 / span 2;
  }
}

.sectionHeader {
  margin-bottom: 1rem;
  @media (min-width: 64em) {
    grid-column: 1 / 2;
  }

  & > .sectionTitle {
    font-size: var(--font-size-xl);
    line-height: var(--line-height-xl);
    color: var(--color-slate-800);
    font-weight: 500;
    padding: 0;
    margin: 0;
  }

  & > .sectionSubtitle {
    font-size: var(--font-size-sm);
    line-height: var(--line-height-sm);
    color: var(--color-slate-500);
    font-weight: 400;
  }
}

.row__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 0.25rem;
  margin-bottom: 1.5rem;
  grid-column: 1 / -1;

  @media (min-width: 40em) {
    grid-column: auto;
  }
}

.stat__header {
  color: var(--color-slate-700);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
  font-weight: 600;
}
.stat__body {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  margin-block: 0.5rem;
  margin-inline: 0;
  color: var(--color-slate-800);
  font-weight: 500;

  & > svg {
    height: 1.25rem;
    height: 1.25rem;
    color: var(--color-green-600);

    &.notRecommended {
      color: var(--color-red-600);
    }
  }
}

.arrowedList {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0;
  list-style: none;
  position: relative;
  padding: 0;

  & > li {
    position: relative;
    padding-inline-start: 2rem;
    font-size: var(--font-size-base);
    line-height: var(--line-height-base);
    color: var(--color-slate-800);
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 1.5rem;
      height: 1rem;
      content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.3515 6.35147C12.8202 5.88284 13.5799 5.88284 14.0486 6.35147L18.8486 11.1515C19.3172 11.6201 19.3172 12.3799 18.8486 12.8485L14.0486 17.6485C13.5799 18.1172 12.8202 18.1172 12.3515 17.6485C11.8829 17.1799 11.8829 16.4201 12.3515 15.9515L15.103 13.2L6.00005 13.2C5.33731 13.2 4.80005 12.6627 4.80005 12C4.80005 11.3373 5.33731 10.8 6.00005 10.8H15.103L12.3515 8.04853C11.8829 7.5799 11.8829 6.8201 12.3515 6.35147Z' fill='%230284C7'/%3E%3C/svg%3E%0A");
    }
  }
}

.recommends {
  text-decoration: underline;
  text-decoration-color: var(--color-green-600);
  text-decoration-thickness: 2px;
}

.notRecommends {
  text-decoration: underline;
  text-decoration-color: var(--color-red-600);
  text-decoration-thickness: 2px;
}
