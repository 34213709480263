.herosection {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  max-width: 80rem;
  margin: 0 auto 2.5rem;
  padding: 3rem 1rem 0;

  @media (min-width: 40em) {
    padding: 2.5rem 2.5rem 0;
  }

  @media (min-width: 80em) {
    padding: 5rem 2.5rem;
  }

  .heroTitle,
  .heroSearch {
    width: 100%;
  }

  .heroTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  h2 {
    margin: 0;
    margin-bottom: 1.5rem;
    position: relative;
    font-size: 8vw;
    line-height: 1.2;
    font-weight: 800;
    text-align: center;
    color: var(--color-slate-800);
    white-space: nowrap;
    z-index: 10;

    background: linear-gradient(
      91.75deg,
      #0284c7 2%,
      #c026d3 65%,
      #ec4899 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    @media (min-width: 64em) {
      font-size: 4.5rem;
    }
    .highlightWord {
      display: inline;
      z-index: 10;
    }
    .highlight {
      --highlight-height: 0;
      --highlight-left: 0;
      --highlight-top: 0;
      position: absolute;
      z-index: -9999;
      width: 9ch;
      height: calc(var(--highlight-height));
      top: calc(var(--highlight-top) + 2%);
      left: var(--highlight-left);
      color: rgba(251, 191, 36, 1);
      background-image: url("data:image/svg+xml,%3Csvg   viewBox='0 0 460 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M378.776 15.3313C332.878 13.6007 275.952 12.0383 231.57 12.6914C142.555 14.0013 89.2235 15.6885 0.557585 20.0001L0 7.47393C88.7958 3.1561 142.257 1.46473 231.401 0.152971C276.099 -0.504756 333.274 1.06778 379.209 2.7998C402.19 3.66634 422.384 4.57385 436.833 5.26476C444.058 5.61022 449.848 5.90157 453.832 6.10662C455.825 6.20914 457.366 6.29009 458.409 6.34545C458.931 6.37313 459.329 6.3944 459.596 6.40878L460 6.43061C460.001 6.43067 460.002 6.43071 459.689 12.692C459.377 18.9534 459.377 18.9534 459.376 18.9533L458.979 18.9319C458.715 18.9177 458.32 18.8965 457.801 18.869C456.763 18.814 455.228 18.7333 453.242 18.6311C449.27 18.4267 443.495 18.1361 436.285 17.7913C421.865 17.1018 401.711 16.1961 378.776 15.3313Z' fill='%23FBBF24'/%3E%3C/svg%3E");
      background-position: left bottom;
      background-repeat: no-repeat;
    }
  }
}

.heroSectionSearch {
  @media (min-width: 80em) {
    padding: 2.5rem 2.5rem;
  }
}

.quote {
  text-align: center;
  & > p {
    margin: 0;
    color: var(--color-blue-900);
    font-size: var(--font-size-base);
    line-height: var(--line-height-base);
    font-weight: 500;
    font-style: italic;

    @media (min-width: 40em) {
      font-size: var(--font-size-2xl);
      line-height: var(--line-height-2xl);
      font-weight: 500;
    }
  }
  & > span {
    color: var(--color-blue-900);
    font-size: var(--font-size-sm);
    line-height: var(--line-height-sm);
    font-weight: 400;

    @media (min-width: 40em) {
      font-size: var(--font-size-base);
      line-height: var(--line-height-base);
    }
  }
}

.filters {
  width: 100%;
  display: flex;
  max-width: 32rem;
  margin: 0 auto;

  & > * {
    width: 100%;
  }
}

.filtersRow {
  flex-direction: column;
  max-width: none;
  gap: 1rem;

  @media (min-width: 57.5em) {
    flex-direction: row;
    flex: 1 0 21%;
  }

  // & > * {
  //   @media (min-width: 60em) {
  //     flex: 1 0 21%;
  //     flex-wrap: nowrap;
  //   }
  // }

  button {
    margin-top: 0.25rem;
    align-self: center;
    width: 100%;
    @media (min-width: 57.5em) {
      margin-top: 0;
    }
  }
}

.displayNone {
  display: none;
}
