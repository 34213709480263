@import url("https://rsms.me/inter/inter.css");

:root,
*::before,
*::after {
  --color-white: #ffffff;
  --color-slate-50: #f8fafc;
  --color-slate-100: #f1f5f9;
  --color-slate-200: #e2e8f0;
  --color-slate-300: #cbd5e1;
  --color-slate-400: #94a3b8;
  --color-slate-500: #64748b;
  --color-slate-600: #475569;
  --color-slate-700: #334155;
  --color-slate-800: #0f172a;
  --color-blue-50: #f0f9ff;
  --color-blue-100: #e0f2fe;
  --color-blue-200: #bae6fd;
  --color-blue-300: #7dd3fc;
  --color-blue-600: #0284c7;
  --color-blue-800: #075985;
  --color-blue-900: #0c4a6e;
  --color-pink-300: #f9a8d4;
  --color-pink-700: #be185d;
  --color-pink-800: #9d174d;
  --color-green-600: #16a34a;
  --color-green-800: #166534;
  --color-red-600: #e11d48;
  --font-size-xs: 0.75rem;
  --line-height-xs: 1rem;
  --font-size-sm: 0.875rem;
  --line-height-sm: 1.25rem;
  --font-size-base: 1rem;
  --line-height-base: 1.5rem;
  --font-size-lg: 1.125rem;
  --line-height-lg: 1.75rem;
  --font-size-xl: 1.25rem;
  --line-height-xl: 1.75rem;
  --font-size-2xl: 1.5rem;
  --line-height-2xl: 2rem;
  --font-size-3xl: 1.875rem;
  --line-height-3xl: 2.25rem;
  --border-radius: 0.5rem;
}

body {
  width: 100%;
  min-height: 100vh;
  font-family: "Inter", sans-serif;
  background-color: var(--color-slate-50);
}

main {
  padding: 1rem;
  margin-bottom: 3rem;
  width: 100%;

  @media (min-width: 40em) {
    padding: 2.5rem;
  }
  @media (min-width: 48em) {
    padding: 2.5rem;
    margin-bottom: 6rem;
  }
  @media (min-width: 80em) {
    max-width: 75rem;
    margin: 0 auto 6rem;
  }
}

.content {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto 1fr auto;
  background-image: url(../assets/BgBlur.svg),
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='11' viewBox='0 0 20 16'%3E%3Cg fill='%23f8fafc' fill-opacity='0.4'%3E%3Cpath fill-rule='evenodd' d='M0 .04C2.6.22 4.99 1.1 7 2.5A13.94 13.94 0 0 1 15 0h4c.34 0 .67.01 1 .04v2A12 12 0 0 0 7.17 12h5.12A7 7 0 0 1 20 7.07V14a5 5 0 0 0-3-4.58A5 5 0 0 0 14 14H0V7.07c.86.12 1.67.4 2.4.81.75-1.52 1.76-2.9 2.98-4.05C3.79 2.83 1.96 2.2 0 2.04v-2z'/%3E%3C/g%3E%3C/svg%3E");
  background-position: -15% -10%, right top;
  background-repeat: no-repeat, repeat;
  background-blend-mode: hard-light;
  min-height: 100vh;

  @media (min-width: 40em) {
    background-position: 90% top, right top;
  }
}

input[type="text"],
input[type="email"],
select,
textarea,
button {
  &::placeholder {
    color: var(--color-slate-400);
  }
  &:placeholder-shown {
    text-overflow: ellipsis;
  }
  &:focus-visible {
    outline: var(--color-blue-600) auto 1px;
  }
}

.visually-hidden {
  border: 0;
  padding: 0;
  margin: 0;
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(
    1px 1px 1px 1px
  ); /* IE6, IE7 - a 0 height clip, off to the bottom right of the visible 1px box */
  clip: rect(
    1px,
    1px,
    1px,
    1px
  ); /*maybe deprecated but we need to support legacy browsers */
  clip-path: inset(
    50%
  ); /*modern browsers, clip-path works inwards from each corner*/
  white-space: nowrap; /* added line to stop words getting smushed together (as they go onto seperate lines and some screen readers do not understand line feeds as a space */
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

a {
  color: var(--color-blue-600);

  &:hover {
    color: var(--color-blue-800);
  }

  &:focus {
    outline: var(--color-blue-600) auto 1px;
  }
}

h2 {
  font-size: var(--font-size-3xl);
  line-height: var(--line-height-3xl);
  color: var(--color-slate-800);
  font-weight: 600;
  padding: 0;
  margin: 0;
}

h3 {
  font-size: var(--font-size-xl);
  line-height: var(--line-height-xl);
  color: var(--color-slate-700);
  font-weight: 600;
  padding: 0;
  margin: 0;
}

.capitalize {
  text-transform: capitalize;
}
