.rangeWrapper {
  width: 100%;
  .rangeInput {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 0 1rem;
  }

  .rangeBubble {
    margin: 0 0.5rem;
    margin-bottom: 0.25rem;
    .bubble {
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 99999px;
      background-color: var(--color-blue-200);
      color: var(--color-blue-600);
      font-size: var(--font-size-xs);
      line-height: var(--line-height-xs);
      font-weight: 500;
      transform: translateX(-5px);
    }
  }

  .range {
    margin: 0 0.5rem;
    accent-color: var(--color-blue-600);
  }

  .stepMoney {
    box-sizing: content-box;
    width: 100%;
    max-width: 100%;
    margin: 0 0.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 32px;
    position: relative;

    button {
      appearance: none;
      background: transparent;
      border: none;
      border-radius: 99999px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      margin: 0;
      cursor: pointer;

      &.activeMoney {
        background-color: var(--color-blue-200);

        svg {
          color: var(--color-blue-600);
        }
      }

      &:first-child {
        margin-left: -0.5rem;
      }

      svg {
        height: 1rem;
        width: 1rem;
        color: var(--color-slate-500);
      }

      &:hover {
        background-color: var(--color-blue-200);

        svg {
          color: var(--color-blue-600);
        }
      }
    }
  }
}
