.button {
  appearance: none;
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  margin-block-end: 0.5rem;
  color: var(--color-blue-600);
  border-radius: 6px;
  border: none;
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  font-weight: 500;
  transition: background-color 350ms;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: var(--color-blue-200);
  }
  & > svg {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    color: var(--color-blue-600);
  }
}
.detail {
  width: 100%;
  max-width: 60rem;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  padding: 2rem;
  background-color: var(--color-white);
  background-image: url(../assets/cardBg--yes.svg);
  background-position: center bottom;
  background-repeat: repeat-x;
  border: 1px solid var(--color-slate-200);
  border-radius: var(--border-radius);
  box-shadow: 0 4px 6px -1px rgba(71, 85, 105, 0.1),
    0 2px 4px -2px rgba(71, 85, 105, 0.1);

  @media (min-width: 60em) {
    // min-width: 60rem;
  }

  button {
    width: fit-content;
  }
}

.cardNotRecommended {
  background-image: url(../assets/cardBg--no.svg);
}

p {
  width: 100%;
  color: var(--color-slate-700);
  font-size: var(--font-size-lg);
  line-height: var(--line-height-lg);
}

blockquote {
  position: relative;
  color: var(--color-slate-700);
  font-size: var(--font-size-xl);
  line-height: var(--line-height-xl);
  font-weight: 400;
  font-style: italic;
  padding: 0.5rem 1rem;
  margin: 0 auto 2rem;
  z-index: 10;
  text-decoration: none;
  max-width: 70ch;

  @media (min-width: 60em) {
  }

  &::before {
    position: absolute;
    z-index: -1;
    top: -0.5rem;
    left: 0;
    content: "\201C";
    font-size: 6rem;
    line-height: 6rem;
    color: var(--color-slate-200);
    opacity: 0.4;
  }
}

.date {
  text-align: right;
  color: var(--color-slate-500);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  padding: 0;
  margin-block: 0;
}

.detailHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  @media (min-width: 48em) {
    flex-direction: row;
  }
  img {
    width: 4rem;
    //height: 4rem;
  }
  p {
    color: var(--color-slate-700);
    font-size: var(--font-size-2xl);
    line-height: var(--line-height-2xl);
    margin: 0;
  }
  span {
    text-transform: capitalize;
  }
}

.detailBody,
.detailFooter {
  @media (min-width: 48em) {
    padding-left: 5rem;
  }
}

.detailFooter {
  margin: 2rem 0;
}

.nav {
  width: 100%;
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, auto));
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  @media (min-width: 40em) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, auto));
  }
  @media (min-width: 48em) {
  }
  @media (min-width: 60em) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(1, minmax(0, auto));
  }
}

.navButton {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 1rem;
  border-radius: 6px;
  cursor: pointer;
  text-decoration: none;
  justify-content: flex-start;
  background-color: var(--color-slate-100);

  @media (min-width: 40em) {
    width: 60%;
  }
  @media (min-width: 60em) {
    height: auto;
  }

  .navArrow {
    display: flex;
    align-items: center;
    // padding: 0.25rem 0.5rem;

    gap: 0.5rem;
    color: var(--color-slate-600);
    svg {
      width: 1.5rem;
      height: 1.5rem;
      color: var(--color-slate-500);
    }
  }

  &:hover {
    background-color: var(--color-blue-200) 10%;
    background-color: rgba(186, 230, 253, 0.2);
    .navArrow {
      color: var(--color-blue-600);
      svg {
        color: var(--color-blue-500);
      }
    }
  }
}

.navBody {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem 1rem 1rem 1rem;
  color: var(--color-slate-700);

  .bodyTitle {
    font-weight: 500;
  }

  blockquote {
    margin: 0;
    width: 100%;
    max-width: 100%;
    height: calc(var(--line-height-sm) * 3.5);
    font-size: var(--font-size-sm);
    line-height: var(--line-height-sm);
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipses;
    overflow: hidden;

    &::before {
      position: absolute;
      z-index: -1;
      top: -0.25rem;
      left: 0;
      content: "\201C";
      font-size: 4rem;
      line-height: 4rem;
      color: var(--color-slate-300);
    }
  }
}

.prev,
.navExplore,
.next {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  @media (min-width: 40rem) {
    flex: 1 0 30%;
  }
}

.prev {
  order: 2;

  .navArrow {
    justify-content: flex-start;
  }

  @media (min-width: 60em) {
    order: 1;
  }
}
.next {
  order: 3;
  justify-self: flex-end;
  justify-content: flex-end;

  .navButton {
    justify-content: flex-end;
  }

  .navArrow {
    justify-content: flex-end;
  }
}

.navExplore {
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  order: 1;
  grid-column: 1 / -1;
  .exploreTitle {
    font-weight: 500;
    color: var(--color-slate-600);
  }

  @media (min-width: 40em) {
    grid-column: span 2;
  }
  @media (min-width: 60em) {
    grid-column: 2 / span 1;
  }
}

.explorePill {
  width: fit-content;
  background-color: var(--color-slate-200);
  color: var(--color-slate-600);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  font-weight: 400;
  text-align: center;
  margin: 0;
  padding: 0.25rem 1rem;
  border-radius: 9999px;
  text-transform: capitalize;
  text-decoration: none;

  &:hover {
    background-color: var(--color-blue-200);
  }
}

.recommends {
  text-decoration: underline;
  text-decoration-color: var(--color-green-600);
  text-decoration-thickness: 2px;
}

.notRecommends {
  text-decoration: underline;
  text-decoration-color: var(--color-red-600);
  text-decoration-thickness: 2px;
}
