.footer {
  width: 100%;
  background: conic-gradient(
    from 180deg at 50% 120%,
    #0ea5e9 0deg,
    #0c4a6e 84.38deg,
    #0ea5e9 360deg
  );
}
.footerContent {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, auto));
  grid-template-rows: repeat(2, minmax(0, 1fr));
  align-items: center;
  gap: 0.5rem;
  max-width: 80rem;
  margin: 0 auto;
  padding: 1rem;

  @media (min-width: 45em) {
    grid-template-columns: repeat(3, minmax(0, auto));
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }
  @media (min-width: 51.25em) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }
  .navGrid {
    grid-row: 2;
    grid-column: span 2;
    @media (min-width: 45em) {
      grid-row: 1;
      grid-column: 2;
    }
    @media (min-width: 51.25em) {
      justify-self: center;
    }
  }
  .buttonGrid {
    justify-self: end;
  }
}

.logo {
  margin: 0;
  padding: 0;
  text-decoration: none;

  h1 {
    display: flex;
  }

  svg {
    display: block;
    color: var(--color-white);
  }
  .beta {
    color: var(--color-white);
    font-size: var(--font-size-base);
    line-height: var(--line-height-base);
    font-weight: 500;
    text-decoration: none !important;
  }
}

.footerNav {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;

  a {
    color: var(--color-white);
    font-size: var(--font-size-base);
    line-height: var(--line-height-base);
    font-weight: 500;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    &:hover {
      color: var(--color-white);
      border-bottom: 2px solid var(--color-white);
    }
  }
}

.ctaButton {
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.5rem;
  cursor: pointer;
  background-color: var(--color-blue-200);
  color: var(--color-blue-800);
  border-radius: 6px;
  border: none;
  font-size: var(--font-size-xs);
  line-height: var(--line-height-xs);
  font-weight: 500;
  transition: background-color 350ms;

  @media (min-width: 23.4375em) {
    font-size: var(--font-size-base);
    line-height: var(--line-height-base);
    padding: 0.5rem 1rem;
  }

  &:focus,
  &:hover {
    background-color: var(--color-blue-300);
  }
  & > svg {
    display: block;
    width: 20px;
    height: 20px;
    color: var(--color-blue-600);
  }
}

@media (min-width: 40em) {
  .header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin: 2.5rem 2.5rem 2.5rem;
  }
}
