.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin: 0 auto;
  max-width: 80rem;

  @media (min-width: 40em) {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 2.5rem 2.5rem 2.5rem;
  }
}

.logo {
  margin: 0;
  padding: 0;
  text-decoration: none;

  h1 {
    display: flex;
  }

  svg {
    display: block;
    color: var(--color-slate-800);
  }
  .beta {
    color: var(--color-slate-600);
    font-size: var(--font-size-base);
    line-height: var(--line-height-base);
    font-weight: 500;
    text-decoration: none !important;
  }
}
.ctaButton {
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.5rem;
  background-color: var(--color-pink-700);
  color: var(--color-white);
  border-radius: 6px;
  border: none;
  font-size: var(--font-size-xs);
  line-height: var(--line-height-xs);
  font-weight: 500;
  transition: background-color 350ms;
  text-decoration: none;

  @media (min-width: 23.4375em) {
    font-size: var(--font-size-base);
    line-height: var(--line-height-base);
    padding: 0.5rem 1rem;
  }

  &:focus,
  &:hover {
    color: var(--color-white);
    background-color: var(--color-pink-800);
  }
  & > svg {
    display: block;
    width: 20px;
    height: 20px;
    color: var(--color-pink-300);
  }
}

.cancelButton {
  background-color: var(--color-slate-200);
  color: var(--color-slate-800);
  transition: background-color 350ms;

  &:focus,
  &:hover {
    background-color: var(--color-slate-300);
    color: var(--color-slate-800);
  }
  & > svg {
    color: var(--color-slate-500);
  }
}
