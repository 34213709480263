.card {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  padding: 1rem;
  background-color: var(--color-white);
  background-image: url(../assets/cardBg--yes.svg);
  background-position: center bottom;
  background-repeat: no-repeat;
  border: 1px solid var(--color-slate-200);
  border-radius: var(--border-radius);
  box-shadow: 0 4px 6px -1px rgba(71, 85, 105, 0.1),
    0 2px 4px -2px rgba(71, 85, 105, 0.1);
  cursor: pointer;
  & a {
    text-decoration: none;
  }
}

.cardNotRecommended {
  background-image: url(../assets/cardBg--no.svg);
}

.cardHeader {
  display: flex;
  gap: 1rem;
  align-items: flex-start;

  & > .headerLeft {
    position: relative;
    pointer-events: all;
    // z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: fit-content;
    max-width: 5.5rem;
    height: 6.25rem;
    .imgArea {
      flex-grow: 1;
      display: grid;
      place-content: center;
      border-radius: calc(var(--border-radius) / 2);
      overflow: hidden;
      object-fit: contain;
    }
    img {
      width: 5rem;
      height: auto;
    }
    span {
      width: fit-content;
      text-align: center;
      font-size: var(--font-size-sm);
      line-height: var(--line-height-sm);
      font-weight: 500;
      color: var(--color-blue-800);
      text-decoration: none;
      font-style: italic;
    }
  }
}
.headerRight {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.title {
  font-size: var(--font-size-xl);
  line-height: var(--line-height-xl);
  font-weight: 500;
  color: var(--color-blue-900);
  text-decoration: none;

  .name {
    text-transform: capitalize;
  }
}

.positionPill {
  width: fit-content;
  background-color: var(--color-slate-100);
  color: var(--color-slate-600);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
  font-weight: 400;
  padding: 0.25rem 1rem;
  border-radius: 9999px;
  text-transform: capitalize;
}

.reviewText {
  position: relative;
  color: var(--color-slate-800);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  font-weight: 400;
  font-style: italic;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: calc(var(--line-height-base) * 6);
  padding: 1rem 1rem 0;
  margin-bottom: 1rem;
  z-index: 10;
  text-decoration: none;

  &::before {
    content: "\201c";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    font-size: 6rem;
    line-height: 6rem;
    color: var(--color-slate-200);
  }
}

.cardFooter {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  text-decoration: none;
  .votes {
    display: flex;
    align-items: flex-start;
    gap: 0.125rem;
    font-size: var(--font-size-sm);
    line-height: var(--line-height-sm);
    color: var(--color-slate-500);
  }
  .user {
    display: flex;
    flex-direction: column;
    color: var(--color-slate-500);
    font-size: var(--font-size-sm);
    line-height: var(--line-height-sm);
    font-weight: 500;
    flex-wrap: wrap;
    justify-content: right;
    text-align: right;
    & > .date {
      font-weight: 400;
    }
  }
}
