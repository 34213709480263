.section {
  margin-top: 6rem;

  &:first-child {
    margin-top: 2rem;
  }
}
.sectionHeader {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
  margin: 0 0 1rem 0;
  & h2 {
    font-size: var(--font-size-base);
    line-height: var(--line-height-base);
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    color: var(--color-slate-700);
  }
}

.listWrapper {
  position: relative;
  z-index: 0;
  padding-bottom: 1rem;
  overflow: hidden;
  max-height: 150vh;
  height: 48rem;

  @media (min-width: 40em) {
    max-height: none;
    height: auto;
  }
}
.gradientStart,
.gradientEnd {
  pointer-events: none;
  position: absolute;
  height: 8rem;
  @media (min-width: 40em) {
    top: 0;
    bottom: 0;
    width: 8rem;
    height: 100%;
  }
}

.gradientStart {
  top: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(
    to bottom,
    var(--color-slate-50),
    hsla(0, 0%, 98%, 0)
  );
  @media (min-width: 40em) {
    left: 0;
    background-image: linear-gradient(
      to right,
      var(--color-slate-50),
      hsla(0, 0%, 98%, 0)
    );
  }
}
.gradientEnd {
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(
    to top,
    var(--color-slate-50),
    hsla(0, 0%, 98%, 0)
  );
  @media (min-width: 40em) {
    right: 0;
    left: auto;
    background-image: linear-gradient(
      to left,
      var(--color-slate-50),
      hsla(0, 0%, 98%, 0)
    );
  }
}

.cardList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0;
  margin: 0;
  list-style: none;

  @media (min-width: 40em) {
    height: 45rem;
    flex-direction: row;
    flex-flow: column wrap;
  }

  .reviewCard {
    @media (min-width: 40em) {
      // flex: 1 0 25rem;
      width: 25rem;
    }
  }
}
.reverse {
  @media (min-width: 40em) {
    height: 45rem;
    flex-direction: row;
    flex-flow: column wrap-reverse;
  }
}
