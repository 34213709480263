.comboboxSet {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  position: relative;

  .label {
    color: var(--color-slate-700);
    font-size: var(--font-size-sm);
    line-height: var(--line-height-sm);
    font-weight: 600;
  }

  .inputArea {
    position: relative;
    width: 100%;
    cursor: default;
    overflow: hidden;
    background-color: var(--color-white);
    text-align: left;
    height: 2.5rem;
    border: 1px solid var(--color-slate-300);
    border-radius: 6px;

    &:focus {
      outline: none;
    }

    &:focus-within {
      outline: var(--color-blue-600) auto 1px;
    }

    .input {
      appearance: none;
      border: none;
      width: 100%;
      height: 100%;
      padding: 0.5rem;
      padding-left: 0.75rem;
      padding-right: 2.5rem;

      &:focus {
        outline: none;
      }
    }
    & > .button {
      appearance: none;
      border: none;
      background-color: transparent;
      position: absolute;
      display: flex;
      align-items: center;
      padding-right: 0.5rem;
      top: 0;
      bottom: 0;
      right: 0;

      & > .icon {
        height: 16px;
        width: 16px;
        color: var(--color-slate-400);
      }
    }
    &.withIcon {
      display: flex;
      gap: 0.5rem;
      height: 3rem;
      align-items: center;
      padding-left: 0.5rem;
      svg {
        color: var(--color-blue-600);
        height: 1.5rem;
        width: 1.5rem;
      }

      & > input[type="text"] {
        padding-left: 0;
      }
    }
  }

  & > span {
    font-size: var(--font-size-sm);
    line-height: var(--line-height-sm);
    color: var(--color-slate-500);
  }
}

.leave {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-duration: 100ms;
}

.leaveFrom {
  opacity: 1;
}

.leaveTo {
  opacity: 0;
  display: none;
}

.comboboxOptions {
  position: absolute;
  z-index: 1000;
  top: 4rem;
  max-height: calc(3.5rem * 5 + 0.5rem);
  width: 100%;
  overflow: auto;
  border-radius: 6px;
  background-color: var(--color-white);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  margin-top: 0;
  padding-left: 0;
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -4px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--color-slate-100);
  list-style: none;
}

.groupItem {
  padding: 0.25rem 0.5rem;
  color: var(--color-slate-500);
  font-size: var(--font-size-xs);
  line-height: var(--line-height-xs);
  font-weight: 500;
  letter-spacing: 0.025rem;
  text-transform: uppercase;

  &:not(:first-child) {
    margin-top: 1rem;
  }
}
.listItem {
  position: relative;
  display: flex;
  gap: 1rem;
  cursor: default;
  user-select: none;
  // height: 3.5rem;
  align-items: center;
  padding: 0.5rem 1rem;
  color: var(--color-slate-800);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);

  &.company {
    height: 3.5rem;
  }

  & > .imgWrapper {
    display: flex;
    flex-shrink: 0;
    width: 2.5rem;
    height: 100%;
    align-items: center;
    justify-content: center;
    & > img {
      width: 100%;
      height: auto;
    }
  }

  & > span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 400;

    &.selected {
      font-weight: 500;
    }
  }

  & > .selectIcon {
    display: flex;
    align-items: center;
    padding-left: 0.75rem;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    color: var(--color-blue-800);
  }
  & > .active {
    color: var(--color-blue-800);
  }
}

.active {
  background-color: var(--color-blue-200);
  color: var(--color-blue-800);
}
