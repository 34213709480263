.pageHeader {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  // grid-template-columns: repeat(3, minmax(0, 1fr));
  // max-width: 60rem;

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    @media (min-width: 40em) {
      flex-direction: row;
      gap: 0.5rem;
    }
    .imgArea {
      flex-grow: 1;
      display: grid;
      place-content: center;
    }
    img {
      width: 4rem;
      height: auto;
      border-radius: calc(var(--border-radius) / 2);
      overflow: hidden;
      object-fit: contain;
    }
  }

  .pageActions {
    display: none;
  }
  .pageTitle {
    font-size: var(--font-size-3xl);
    line-height: var(--line-height-3xl);
    color: var(--color-slate-800);
    font-weight: 600;
    padding: 0;
    margin: 0;
  }

  .pageSubtitle {
    font-size: var(--font-size-base);
    line-height: var(--line-height-base);
    color: var(--color-slate-500);
    font-weight: 400;
  }
}

.pageBody {
  // max-width: 60rem;
  display: grid;

  fieldset {
    @media (min-width: 64em) {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 1rem;
    }

    &:first-of-type {
      margin-bottom: 3rem;
    }
    & > div:last-of-type {
      margin-bottom: 1rem;
    }
    .row {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 1rem;

      @media (min-width: 64em) {
        grid-column: 2 / span 2;
      }
    }
    .rowFull {
      grid-template-columns: 1fr;

      @media (min-width: 64em) {
        grid-column: 2 / span 2;
      }
    }
  }
}

.sectionHeader {
  margin-bottom: 1rem;

  @media (min-width: 64em) {
    grid-column: 1 / 2;
  }

  & > .sectionTitle {
    font-size: var(--font-size-xl);
    line-height: var(--line-height-xl);
    color: var(--color-slate-800);
    font-weight: 500;
    padding: 0;
    margin: 0;
  }

  & > .sectionSubtitle {
    font-size: var(--font-size-sm);
    line-height: var(--line-height-sm);
    color: var(--color-slate-500);
    font-weight: 400;
  }
}

.formInput {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-bottom: 1.5rem;
  grid-column: 1 / span 2;

  @media (min-width: 51.25em) {
    grid-column: auto;
  }
  & > .label {
    display: flex;
    gap: 0.25rem;
    align-items: baseline;
    color: var(--color-slate-700);
    font-size: var(--font-size-sm);
    line-height: var(--line-height-sm);
    font-weight: 600;
  }

  & > .input {
    position: relative;
    width: 100%;
    cursor: default;
    overflow: hidden;
    background-color: var(--color-white);
    text-align: left;
    height: 2.5rem;
    border: 1px solid var(--color-slate-300);
    border-radius: 6px;
    padding-left: 0.75rem;
    // padding-right: 2.5rem;

    &:focus {
      outline: none;
    }

    &:focus-within {
      outline: var(--color-blue-600) auto 1px;
    }

    & > input[type="text"] {
      appearance: none;
      border: none;
      width: 100%;
      height: 100%;
      padding: 0.5rem;
      padding-left: 0.75rem;
      padding-right: 2.5rem;

      &:focus {
        outline: none;
      }
    }
  }
  & > .textarea {
    height: 6rem;
    padding: 0.75rem;
  }

  .buttonGroup {
    display: flex;
    gap: 0px;

    & > .selectionButton {
      appearance: none;
      background-color: var(--color-white);
      padding: 0.5rem 0.75rem;
      font-size: var(--font-size-base);
      line-height: var(--line-height-base);
      color: var(--color-slate-700);
      border: 1px solid var(--color-slate-300);
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      &:first-child {
        border-radius: 6px 0 0 6px;
      }
      &:last-child {
        border-radius: 0 6px 6px 0;
      }
      & > svg {
        height: 1.25rem;
        height: 1.25rem;
        color: var(--color-slate-400);
      }
      &.selectionActive {
        background-color: var(--color-blue-200);
        color: var(--color-blue-800);
        border: 1px solid var(--color-blue-200);
        & > svg {
          color: var(--color-blue-600);
        }
      }
    }
  }
  .usernameField {
    display: flex;
    align-items: center;
    gap: 1rem;

    button {
      width: fit-content;
    }
  }
}
.optional {
  display: flex;
  align-items: baseline;
  gap: 0.25rem;
  color: var(--color-slate-500);
  font-size: var(--font-size-xs);
  line-height: var(--line-height-xs);
  font-weight: 400;
}

.formInputHorz {
  flex-direction: row;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  align-items: center;

  @media (min-width: 64em) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
