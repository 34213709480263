.btn {
  appearance: none;
  // height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: var(--color-blue-200);
  color: var(--color-blue-800);
  border-radius: 6px;
  border: none;
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  font-weight: 500;
  transition: background-color 350ms;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: var(--color-blue-300);
  }
  & > svg {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    color: var(--color-blue-600);
  }
}

.lg {
  padding: 0.75rem 1rem;
  & > svg {
    width: 1.75rem;
    height: 1.75rem;
  }
}
.sm {
  padding: 0.25rem 0.5rem;
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
  & > svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.secondary {
  background-color: var(--color-slate-200);
  color: var(--color-slate-800);

  &:hover {
    background-color: var(--color-slate-300);
  }
  & > svg {
    color: var(--color-slate-500);
  }
  &.brand {
    background-color: var(--color-blue-200);
    color: var(--color-blue-800);

    &:hover {
      background-color: var(--color-blue-300);
    }
    & > svg {
      color: var(--color-blue-600);
    }
  }
}

.tertiary {
  background-color: transparent;
  color: var(--color-slate-800);

  &:hover {
    background-color: var(--color-slate-200);
  }
  & > svg {
    color: var(--color-slate-500);
  }
  &.brand {
    color: var(--color-blue-800);

    &:hover {
      background-color: var(--color-blue-200);
    }
    & > svg {
      color: var(--color-blue-600);
    }
  }
}

.btn:disabled,
.buttonDisabled {
  pointer-events: none;
  opacity: 0.5;
}
