.bannerWrapper {
  width: calc(100% + 2rem);
  margin-top: 5rem;
  margin-left: -1rem;
  background: radial-gradient(100% 50% at 50% 100%, #0ea5e9 0%, #0369a1 100%);
  padding: 5rem 1rem 0;
  overflow: hidden;

  @media (min-width: 40em) {
    width: calc(100% + 5rem);
    margin-left: -2.5rem;
    padding: 5rem 2rem 0;
    background: radial-gradient(65% 100% at 70% 100%, #0ea5e9 0%, #0369a1 100%);
  }

  @media (min-width: 80em) {
    width: calc(100% + 8rem);
    margin-left: -4rem;
    border-radius: 32px;
  }
}

.bannerContent {
  display: flex;
  flex-direction: column;
  max-width: 28rem;
  margin: auto;
  gap: 2.5rem;

  @media (min-width: 40em) {
    max-width: 40rem;
    flex-direction: row;
  }
  @media (min-width: 64em) {
    max-width: 60rem;
    flex-direction: row;
  }
}

.bannerText {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  flex-basis: fit-content;

  @media (min-width: 40em) {
    padding-bottom: 5rem;
    justify-content: flex-start;
    align-items: flex-start;
  }
  h2 {
    margin: 0;
    padding: 0;
    width: max-content;
    color: var(--color-white);
    font-size: var(--font-size-3xl);
    line-height: var(--line-height-3xl);
    letter-spacing: -0.025em;
    font-weight: 400;
    text-align: center;
    @media (min-width: 40em) {
      text-align: left;
    }
  }

  p {
    margin: 0;
    padding: 0;
    color: var(--color-blue-50);
    font-size: var(--font-size-lg);
    line-height: var(--line-height-base);
    font-weight: 300;
    text-align: center;
    @media (min-width: 40em) {
      text-align: left;
    }
  }

  a {
    width: 100%;
  }
}

.bannerImg {
  overflow: hidden;

  @media (min-width: 40em) {
    margin-top: 0;
    overflow: initial;
  }

  svg {
    width: 100%;
    height: auto;
    margin-bottom: -50%;
    @media (min-width: 40em) {
      width: 150%;
    }
    @media (min-width: 64em) {
      width: 100%;
    }
  }
}

.ctaButton {
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.5rem;
  background-color: var(--color-pink-700);
  color: var(--color-white);
  border-radius: 6px;
  border: none;
  font-size: var(--font-size-xs);
  line-height: var(--line-height-xs);
  font-weight: 500;
  transition: background-color 350ms;
  text-decoration: none;

  @media (min-width: 23.4375em) {
    font-size: var(--font-size-base);
    line-height: var(--line-height-base);
    padding: 0.5rem 1rem;
  }

  &:focus,
  &:hover {
    color: var(--color-white);
    background-color: var(--color-pink-800);
  }
  & > svg {
    display: block;
    width: 20px;
    height: 20px;
    color: var(--color-pink-300);
  }
}
